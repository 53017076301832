import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link, Element, scroller } from 'react-scroll';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { GridLoader } from 'react-spinners';

import MenuItem from '../components/MenuItem';
import MenuItemEdit from '../components/MenuItemEdit';
import Popup from '../components/Popup';

function MenuEdit(props) {

    /* Must be logged in. Handle AUTH */
    let { categoryName } = useParams();
    const [menu, setMenu] = useState([]);
    const [stagedChanges, setStagedChanges] = useState([]);
    const [confirmReset, setConfirmReset] = useState(false);
    const [confirmSaveOptionsUrl, setConfirmSaveOptionsUrl] = useState(false);
    const [update, setUpdate] = useState(0);

    const { rem } = useWindowDimensions();

    const navigate = useNavigate();

    /**
     * [
     *  {
     *      category:
     *      url:
     *      items: [
     *          {
     *              id:
     *              name:
     *              url:
     *              thumbnail:
     *              price_tag:
     *              price:
     *              shelf_status:
     *          }
     *      ]
     *  }
     * ]
     */
    useEffect(() => {
        // get all items
        console.log('API - ' + process.env.REACT_APP_API_URL + '/.netlify/functions/menu-edit');
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/menu-edit')
            .then(res => {
                return res.json();
            })
            .then(res => {
                console.log('fetch: ' + JSON.stringify(res));
                setMenu(res);
            });
    }, []);

    useEffect(() => {
        if (categoryName) {
            scroller.scrollTo('/' + categoryName, {
                duration: 1000,
                offset: -98 * rem
            });
        }
    }, [menu]);

    useEffect(() => {
        console.log('confirm:',confirmSaveOptionsUrl);
    }, [confirmSaveOptionsUrl]);

    const addChange = (call, id, change) => {
        const tempChanges = [...stagedChanges];
        const replaceId = tempChanges.findIndex(change => {
            return change.call === call && change.id === id;
        });
        if (replaceId >= 0) {
            tempChanges[replaceId] = {
                call, id, change
            }
            console.log('tempChanges exists:', tempChanges);
            setStagedChanges(tempChanges);
            return;
        }
        tempChanges.push({
            call, id, change
        });
        console.log('tempChanges new:', tempChanges);
        setStagedChanges(tempChanges);
    }

    const removeChange = (call, id, change) => {
        const tempChanges = [...stagedChanges];
        const replaceId = tempChanges.findIndex(change => {
            return change.call === call && change.id === id;
        });
        if (replaceId >= 0) {
            tempChanges.splice(replaceId, 1);
            console.log('tempChanges remove:', tempChanges);
            setStagedChanges(tempChanges);
            return;
        }
        console.log('tempChanges failed remove:', tempChanges);
    }

    const resetAllChangesOnClick = () => {
        setConfirmReset(false);
        setStagedChanges([]);
        setUpdate((update+1)%2);
    }

    const syncAllChanges = () => {
        const newMenu = JSON.parse(JSON.stringify(menu));
        //get category
        //get item
        let index = -1;
        stagedChanges.forEach(change => {
            const category = newMenu.find(category => {
                index = category.items.findIndex(item => {
                    return item.id === change.id;
                });
                return index >= 0;
            });
            if(category) {
                if(change.call === 'set-status') {
                    category.items[index].shelf_status = change.change;
                }
            }
        });
        setMenu(newMenu);
        setConfirmReset(false);
        setStagedChanges([]);
        setUpdate((update+1)%2);
    }

    const saveAllChangesOnClick = () => {
        //for each staged change
        console.log('stagedChanges:',stagedChanges);
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/set-status-bulk', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                "Content-Type": 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(stagedChanges),
            isBase64Encoded: false
        })
        .then((res) => {
            console.log('res:',res);
            return res.json();
        });
        syncAllChanges();
    }

    const editOnClick = (url) => {
        console.log('url:',url);
        if(stagedChanges.length > 0) {
            setConfirmSaveOptionsUrl(url);
            return;
        }
        navigate('/menu-edit'+url);
    }

    const discardAndContinueOnClick = () => {
        navigate('/menu-edit'+confirmSaveOptionsUrl);
        setConfirmSaveOptionsUrl(false);
    }

    const saveAndContinueOnClick = () => {
        saveAllChangesOnClick();
        navigate('/menu-edit'+confirmSaveOptionsUrl);
    }

    const sidebarCategoryFrames = [];
    const categoryFrames = [];
    console.log(menu);
    for (const category of menu) {
        // console.log(category);
        sidebarCategoryFrames.push(<Link activeClass='selected-category' to={category.url} spy={true} smooth={true} duration={350} offset={-98 * rem}>
            <p className='font-24 hover-pointer bold-hover' name={category.category}>{category.category}</p>
        </Link>);
        categoryFrames.push(<Element name={category.url}>
            <div className='frame column align-start stretch' id={category.url} style={{ padding: '32rem 0 0 0' }}>
                <p className='font-48 primary bold'>{category.category}</p>
                <div className='frame align-start-start stretch wrap' gap='32rem'>
                    {category.items.map((item, i) => <MenuItemEdit update={update} id={item.id} name={item.name} price={item.price_tag} flavor={item.flavor} url={category.url + item.url} status={item.shelf_status} addChange={addChange} removeChange={removeChange} editOnClick={editOnClick}></MenuItemEdit>)}
                    <MenuItemEdit id={0} name={'Add New Item'} price={''} flavor={''} url={category.url + '/create-item'} status={'available'} addChange={() => { }} removeChange={() => { }} editOnClick={editOnClick}></MenuItemEdit>
                </div>
            </div>
        </Element>);
    }

    return (
        <div className='frame column align-start stretch flex-100 true-white'>
            <div className='frame align-start stretch' style={{ gap: '80rem' }}>
                <div className='frame column justify-center align-start true-white sticky' style={{ top: '52rem', gap: '32rem', padding: '64rem 0 64rem 80rem' }}>
                    <p className='font-64 casual primary'>Menu</p>
                    {sidebarCategoryFrames}
                </div>
                {menu.length > 0 ?
                    <div className='frame column align-start flex-100 offwhite' style={{ padding: '40rem 80rem 360rem 80rem' }}>
                        {categoryFrames}
                    </div> :
                    <div className='frame column align-start flex-100 offwhite' style={{ padding: '40rem 80rem 360rem 80rem' }}>
                        <GridLoader color='#FF5271' />
                        <p className='font-20'>Menu not showing up? Try refreshing or contacting customer support.</p>
                    </div>
                }
            </div>
            {stagedChanges.length > 0 && <div className='frame column fixed' style={{ left: '50%', bottom: '100rem', transform: 'translateX(-50%)', gap: '12rem' }}>
                <div className='frame center green inverted hover-pointer' style={{ padding: '16rem 32rem', borderRadius: '18rem' }} onClick={() => setConfirmReset(true)}>
                    <p className='font-28 bold'>Discard All Changes</p>
                </div>
                <div className='frame center green hover-pointer' style={{ padding: '16rem 32rem', borderRadius: '18rem' }} onClick={() => saveAllChangesOnClick()}>
                    <p className='font-28 bold'>Save All Changes</p>
                </div>
            </div>}
            {confirmReset && <Popup handleGoBack={() => setConfirmReset(false)}>
                <div className='frame column center stretch' style={{ gap: '12rem' }}>
                    <div className='frame green inverted hover-pointer' style={{ padding: '16rem 32rem', borderRadius: '18rem' }} onClick={() => setConfirmReset(false)}>
                        <p className='font-28 bold'>Go Back</p>
                    </div>
                    <div className='frame green hover-pointer' style={{ padding: '16rem 32rem', borderRadius: '18rem' }} onClick={() => resetAllChangesOnClick()}>
                        <p className='font-28 bold'>Confirm Reset</p>
                    </div>
                </div>
            </Popup>}
            {confirmSaveOptionsUrl && <Popup handleGoBack={() => setConfirmSaveOptionsUrl(false)}>
                <div className='frame column center stretch' style={{ gap: '12rem' }}>
                    <div className='frame green inverted hover-pointer' style={{ padding: '16rem 32rem', borderRadius: '18rem' }} onClick={() => setConfirmSaveOptionsUrl(false)}>
                        <p className='font-28 bold'>Go Back</p>
                    </div>
                    <div className='frame green inverted hover-pointer' style={{ padding: '16rem 32rem', borderRadius: '18rem' }} onClick={() => discardAndContinueOnClick()}>
                        <p className='font-28 bold'>Discard All Changes and Continue</p>
                    </div>
                    <div className='frame green hover-pointer' style={{ padding: '16rem 32rem', borderRadius: '18rem' }} onClick={() => saveAndContinueOnClick()}>
                        <p className='font-28 bold'>Save All Changes and Continue</p>
                    </div>
                </div>
            </Popup>}
        </div>
    );
}

export default MenuEdit;