import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../partials/Home';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Menu from '../partials/Menu';
import Item from '../partials/Item';
import Checkout from '../partials/Checkout';
import PaymentComplete from '../partials/PaymentComplete';
import About from '../partials/About';
import Contact from '../partials/Contact';
import MenuEdit from '../partials/MenuEdit';
import ItemEdit from '../partials/ItemEdit';
import RequiresAdmin from '../components/RequiresAdmin';

export default function Desktop(props) {
    return (
        <>
            <Header />
            <Routes>
                <Route index element={<Home />} />
                <Route path='*' element={
                    <div className='frame flex-200 offwhite center'>
                        <p className='font-20 black'>404 Not Found :{'('}</p>
                    </div>
                } />
                <Route path='/menu' element={<Menu />}>
                    <Route path='/menu/:categoryName' element={<Menu />} />
                </Route>
                <Route path='/menu/:categoryName/:itemName' element={<Item />} />
                <Route path='/menu-edit' element={<RequiresAdmin>
                    <MenuEdit />
                </RequiresAdmin>}>
                    <Route path='/menu-edit/:categoryName' element={<RequiresAdmin>
                        <MenuEdit />
                    </RequiresAdmin>} />
                </Route>
                <Route path='/menu-edit/:categoryName/:itemName' element={<RequiresAdmin>
                    <ItemEdit />
                </RequiresAdmin>} />
                <Route path='/checkout/*' element={<Checkout />} />
                <Route path='/order-complete' element={<PaymentComplete />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
            </Routes>
            {/* <div className='frame flex-100 offwhite center'></div> */}
            <Footer />
        </>
    )
}