import { photoMap2 } from "../../functions/PhotoMap";
import { getSelectorNames } from "../../functions/SelectorHandler";

import Trash from '../../assets/Trash.svg';
import Logo from '../../assets/CreateYourOwnIcon.svg';

export default function OrderItem(props) {
    const {
        id, item,
        size, flavor, toppings, frosting, inscription, inscriptionStyle, colors, notes, price,
        type, handleClickRemove
    } = props;

    const inscriptionStyles = [{ 'id': 1, 'name': 'Cursive' }, { 'id': 2, 'name': 'Print' }];

    console.log(inscriptionStyle);

    if (type === 'vert') return (
        <div className='frame column align-items-start start stretch overflow-scroll' style={{ gap: '32rem', width: '560rem', marginBottom: '32rem' }}>
            <img style={{ width: '236rem', height: '186rem', border: '2.5rem solid var(--lightest-grey)', borderRadius: '10rem' }} src={item.thumbnail || (photoMap2.get(item.url) ? photoMap2.get(item.url) : Logo)}></img>
            <div className='frame column align-items-start flex-100 stretch' style={{ gap: '10rem' }}>
                <div className='frame row justify-between stretch'>
                    <p className='font-24 bold flex-100'>{item.size_options.length > 1 && item.size_options[size]?.name} {item.name} {item.second_name}</p>
                    <p className='font-32 primary bold frame stretch'>${price}</p>
                </div>
                {getSelectorNames(item.flavor_options, flavor).length > 0 && <p className='font-20'>Flavor: {getSelectorNames(item.flavor_options, flavor)}</p>}
                {getSelectorNames(item.topping_options, toppings).length > 0 && <p className='font-20'>Toppings: {getSelectorNames(item.topping_options, toppings)}</p>}
                {getSelectorNames(item.frosting_options, frosting).length > 0 && <p className='font-20'>Frosting: {getSelectorNames(item.frosting_options, frosting)}</p>}
                {inscription.length > 0 && <p className='font-20'>Inscription: <span className='lighter-grey'>{inscription} ({typeof (inscriptionStyle) === typeof (0) ? inscriptionStyles[inscriptionStyle].name : inscriptionStyle})</span></p>}
                {colors.length > 0 && <p className='font-20'>Colors: <span className='lighter-grey'>{colors}</span></p>}
                <p className='font-20'>Notes: <span className='lighter-grey'>{notes.length > 0 ? notes : 'N/A'}</span></p>
            </div>
        </div>
    );
    if (type === 'horiz') return (
        <div className='frame row align-start stretch overflow-scroll' style={{ gap: '32rem' }}>
            <img style={{ width: '236rem', height: '186rem', border: '2.5rem solid var(--lightest-grey)', borderRadius: '10rem' }} src={item.thumbnail || (photoMap2.get(item.url) ? photoMap2.get(item.url) : Logo)}></img>
            <div className='frame column align-items-start flex-100 stretch' style={{ gap: '10rem', maxHeight: '100rem' }}>
                <p className='font-24 bold'>{item.size_options.length > 1 && item.size_options[size]?.name} {item.name} {item.second_name}</p>
                {getSelectorNames(item.flavor_options, flavor).length > 0 && <p className='font-20'>Flavor: {getSelectorNames(item.flavor_options, flavor)}</p>}
                {getSelectorNames(item.topping_options, toppings).length > 0 && <p className='font-20'>Toppings: {getSelectorNames(item.topping_options, toppings)}</p>}
                {getSelectorNames(item.frosting_options, frosting).length > 0 && <p className='font-20'>Frosting: {getSelectorNames(item.frosting_options, frosting)}</p>}
                {inscription.length > 0 && <p className='font-20'>Inscription: <span className='lighter-grey'>{inscription} ({inscriptionStyle})</span></p>}
                {colors.length > 0 && <p className='font-20'>Colors: <span className='lighter-grey'>{colors}</span></p>}
                <p className='font-20'>Notes: <span className='lighter-grey'>{notes.length > 0 ? notes : 'N/A'}</span></p>
            </div>
            <div className='frame column flex-100 end stretch' style={{ gap: '32rem' }}>
                <p className='frame font-20 bold frame align-self-end'>${price}</p>
                {handleClickRemove &&
                    <div className='frame align-self-end'>
                        <img className='hover-pointer' style={{ width: '30rem', height: '30rem' }} src={Trash} onClick={handleClickRemove}></img>
                    </div>
                }
            </div>
        </div>
    );
};


{/* <Frame key={id} className='cart-item align-items-start start' gap='32rem'>
<img className={'cart-item-thumbnail ' + item.flavor} src={photoMap2.get(item.url)}></img>
<Frame className='cart-item-info column align-items-start flex-100 stretch' gap='10rem'>
    <Frame className='align-items-start' gap='8rem'>
        <p className='cart-item-name bold'>{item.size} {item.name} {item.secondName}</p>
        {(discounts.includes('VALEN10<3') && (item.size.includes('Heart') || item.name.includes('Heart'))) && <Frame className='mobile-item-discount font-21' gap='4rem'>
            <img className='mobile-item-discount-tag' src={RedDiscountTag}></img>
            <p className='primary'>-10%</p>
        </Frame>}
    </Frame>
    {item.flavor.length > 1 && <p className='cart-item-flavor'>Flavor: {item.flavor}</p>}
    {item.toppings.length > 1 && <p className='cart-item-toppings'>Toppings: {item.toppings.length > 0 ? item.toppings.join(', ') : 'N/A'}</p>}
    {item.frosting.length > 1 && <p className='cart-item-frosting'>Frosting: {item.frosting}</p>}
    {item.category.includes('Cake') && <p className='cart-item-message'>Inscription: <span className='lighter-grey'>{item.inscription ? item.inscription : 'N/A'}</span></p>}
    <p className='cart-item-message'>Notes: <span className='lighter-grey'>{item.notes ? item.notes : 'N/A'}</span></p>
    <Frame className='cart-item-edit center' gap='24rem'>
        <Frame className='cart-item-quantitycontrol center' gap='12rem' padding='14rem 18rem' borderRadius='7rem'>
            <img className={'minus-sign cart-quantity-sign' + (quantities[id] <= 1 ? ' disabled' : ' hover-pointer')}
                src={MinusSign}
                onClick={quantities[id] > 1 ? () => {
                    const tempQuantities = quantities.map((q, i) => {
                        if (i === id) {
                            return q - 1;
                        }
                        return q;
                    });
                    setQuantities(tempQuantities);
                } : () => { }}></img>
            <p className='cart-item-quantity'>{quantities[id]}</p>
            <img className={'plus-sign cart-quantity-sign' + (quantities[id] >= 10 ? ' disabled' : ' hover-pointer')}
                src={PlusSign}
                onClick={quantities[id] <= 9 ? () => {
                    const tempQuantities = quantities.map((q, i) => {
                        if (i === id) {
                            return q + 1;
                        }
                        return q;
                    });
                    setQuantities(tempQuantities);
                } : () => { }}></img>
        </Frame> */}
{/* <p className='cart-item-editbutton editbutton hover-pointer underlined'>Edit</p> */ }
{/* </Frame>
</Frame>
<Frame className='column end stretch' gap='8rem'>
    <p className={'cart-item-price bold frame stretch' + ((discounts.includes('VALEN10<3') && (item.size.includes('Heart') || item.name.includes('Heart'))) ? 'strikethrough' : '')}>${item.price * quantities[id]}</p>
    {(discounts.includes('VALEN10<3') && (item.size.includes('Heart') || item.name.includes('Heart'))) && <p className='cart-item-price bold stretch primary'>{USDollar.format(item.price * quantities[id] * .9)}</p>}
    <Frame className='flex-100 end'>
        <img className='delete-item-button hover-pointer frame' src={Trash} onClick={() => {
            console.log('ATTEMPT DELETE: ' + id);
            setConfirmDelete(id);
        }}></img>
    </Frame>
</Frame>
</Frame> */}


{/* <Frame key={id} className='order-item align-items-start stretch' gap='32rem'>
                <img className='order-item-thumbnail' src={photoMap2.get(item.url)}></img>
                <Frame className='order-item-info column flex-100' gap='16rem'>
                    <Frame gap='8rem'>
                        <p className='order-item-name bold'>{item.size} {item.name} {item.secondName}<span className=''>&nbsp;&nbsp;&nbsp;&nbsp;x{quantities[id]}</span></p>
                        {(discounts.includes('VALEN10<3') && (item.size.includes('Heart') || item.name.includes('Heart'))) && <Frame className='mobile-item-discount font-21' gap='4rem'>
                            <img className='mobile-item-discount-tag' src={RedDiscountTag}></img>
                            <p className='primary'>-10%</p>
                        </Frame>}
                    </Frame>
                    {item.flavor.length > 1 && <p className='cart-item-flavor'>Flavor: {item.flavor}</p>}
                    {item.toppings.length > 1 && <p className='cart-item-toppings'>Toppings: {item.toppings.length > 0 ? item.toppings.join(', ') : 'N/A'}</p>}
                    {item.frosting.length > 1 && <p className='cart-item-frosting'>Frosting: {item.frosting}</p>}
                    {item.category.includes('Cake') && <p className='cart-item-message'>Inscription: <span className='lighter-grey'>{item.inscription ? item.inscription : 'N/A'}</span></p>}
                    <p className='cart-item-message'>Notes: <span className='lighter-grey'>{item.notes ? item.notes : 'N/A'}</span></p> */}
{/* <p className='order-item-editbutton editbutton hover-pointer underlined'>Edit</p> */ }
//     </Frame>
//     <Frame className='column'>
//         <p className={'order-item-price bold font-24 ' + ((discounts.includes('VALEN10<3') && (item.size.includes('Heart') || item.name.includes('Heart'))) ? 'strikethrough' : '')}>${item.price * quantities[id]}</p>
//         {(discounts.includes('VALEN10<3') && (item.size.includes('Heart') || item.name.includes('Heart'))) && <p className='cart-item-price bold stretch primary font-24'>{USDollar.format(item.price * quantities[id] * .9)}</p>}
//     </Frame>
// </Frame>