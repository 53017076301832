import React, { useEffect, useState } from 'react';

import Option from './Option';

import GreenDot from '../../assets/GreenDot.png';

var generateID = (function () {
    var id = 0; // This is the private persistent value
    // The outer function returns a nested function that has access
    // to the persistent value.  It is this nested function we're storing
    // in the variable uniqueID above.
    return function () { return id++; };  // Return and increment
})(); // Invoke the outer function after defining it.

const toggleOption = (selected, option, multiSelect, preSelected) => {
    var temp = [...selected.filter((o) => o.id !== option.id)];
    if (selected.length === multiSelect) {
        temp = [...selected.filter((o) => o.id !== selected[preSelected ? 1 : 0].id)];
    }
    console.log('temp: ' + JSON.stringify(temp, null, 4));
    console.log('conditions: ' + (multiSelect < 0) + ', ' + (temp.length < multiSelect) + ', ' + (temp.length === selected.length));
    if ((multiSelect < 0 || temp.length < multiSelect) && !selected.find((o) => o.id === option.id)) {
        temp.push(option);
    }
    return temp;
}

export default function Selector(props) {
    const selectorID = generateID();
    const {
        options, defaultOption, preSelected, showPreSelected, multiSelect, deselect,
        state, setState, select, close, confirm, cancel,
        title, label, info,
        noStretch, wrap, column, selectorClass, selectorStyle, optionsClass, optionClass, optionStyles
    } = props;

    const [selected, setSelected] = useState(defaultOption ? defaultOption : state);
    const [multiSelected, setMultiSelected] = useState(preSelected ? preSelected : []);

    useEffect(() => {
        if (multiSelect) setMultiSelected(state);
    }, [state]);

    /*STYLES*/
    const defaultSelectorStyle = {
        gap: '8rem',
        padding: '0'
    }
    const combinedSelectorStyle = {
        ...defaultSelectorStyle,
        ...selectorStyle
    }

    // const defaultOptionStyle = {
    //     gap: '8rem',
    //     padding: '0'
    // }
    const addedOptionStyle = {
        // marginLeft: wrap ? '-40rem' : '0',
        // marginRight: wrap ? '40rem' : '0'
    }

    /*PRECALCULATIONS OPTIONS*/
    const description = title || label || info;

    // console.log('options: '+JSON.stringify(options));
    const idOffset = options[0].id !== 0 ? -1 : 0;

    return (
        <div className={'frame column align-start ' + (noStretch ? '' : 'stretch ') + (selectorClass ? selectorClass : '')} style={combinedSelectorStyle}>
            {description && <div className='frame row justify-between stretch' style={{ gap: '8rem' }}>
                {title}
                {label}
                {info}
            </div>}
            <div className={'frame ' + (column ? 'column align-start ' : 'row align-center ') + (optionsClass ? optionsClass + ' ' : '') + (wrap ? 'wrap ' : '')} style={{ gap: '8rem' }}>
                {options.map((option, i) => {
                    const id = (option.id ? parseInt(option.id) + parseInt(idOffset) : i);
                    const isPreselected = preSelected?.find((o) => o.id === option.id);
                    if (preSelected && !showPreSelected && isPreselected) return;
                    const selected = multiSelect ? multiSelected.find((o) => o.id === option.id) : state === id;
                    return <div className='frame row center' style={{ gap: '4rem', padding: '0' }}>
                        {<Option selected={selected} option={option} class={optionClass} styles={optionStyles} addedStyle={addedOptionStyle} handleOnClick={() => {
                            if (isPreselected) return;
                            if (multiSelect) {
                                const newSelected = toggleOption(multiSelected, option, multiSelect, preSelected);
                                console.log('new_selected: ' + JSON.stringify(newSelected, null, 4));
                                setState(newSelected);
                                setMultiSelected(newSelected);
                            }
                            else setState(i);
                            if (confirm) {
                                setSelected(i);
                            }
                            if (select) select();
                        }} />}
                        {option.price > 0 && <div className='hover-appear'>
                            <p className='primary semi-bold' style={{ fontSize: optionStyles.default.fontSize }}>+${option.price}</p>
                        </div>}
                    </div>
                })}
            </div>
            {confirm && <div className='frame row justify-end stretch' style={{ gap: '8rem' }}>
                <div className='frame center primary solid hover-pointer' style={{ padding: '8rem 16rem', borderRadius: '16rem' }}
                    onClick={() => {
                        confirm(selected);
                        if (close) close();
                    }}>
                    <p className='bold font-18'>Confirm</p>
                </div>
                {cancel && <div className='frame center primary inverted hover-pointer' style={{ padding: '8rem 16rem', borderRadius: '16rem' }}
                    onClick={() => {
                        cancel();
                        if (close) close();
                    }}>
                    <p className='bold font-18'>Cancel</p>
                </div>}
            </div>}
        </div>
    )
}

export function EditSelector(props) {
    const selectorID = generateID();
    const {
        options, defaultOption, preSelected, showPreSelected, multiSelect, deselect,
        ogState, state, setState, select, close, confirm, cancel,
        title, label, info,
        noStretch, wrap, column, selectorClass, selectorStyle, optionsClass, optionClass, optionStyles
    } = props;

    const [selected, setSelected] = useState(defaultOption ? defaultOption : state);
    const [multiSelected, setMultiSelected] = useState(preSelected ? preSelected : []);

    const [confirmReset, setConfirmReset] = useState(false);

    useEffect(() => {
        if (multiSelect) setMultiSelected(state);
    }, [state]);

    const changed = ogState !== state;

    /*STYLES*/
    const defaultSelectorStyle = {
        gap: '8rem',
        padding: '0'
    }
    const combinedSelectorStyle = {
        ...defaultSelectorStyle,
        ...selectorStyle
    }

    // const defaultOptionStyle = {
    //     gap: '8rem',
    //     padding: '0'
    // }
    const addedOptionStyle = {
        // marginLeft: wrap ? '-40rem' : '0',
        // marginRight: wrap ? '40rem' : '0'
    }

    /*PRECALCULATIONS OPTIONS*/
    const description = title || label || info;

    // console.log('options: '+JSON.stringify(options));
    const idOffset = options[0].id !== 0 ? -1 : 0;

    return (
        <div className={'frame relative column align-start ' + (noStretch ? '' : 'stretch ') + (selectorClass ? selectorClass : '')} style={combinedSelectorStyle}>
            {description && <div className='frame relative row justify-between stretch' style={{ gap: '8rem', backgroundColor: (changed ? 'var(--light-green)' : 'transparent') }}>
                {title}
                {label}
                {info}
                {changed &&
                    <img className='frame absolute hover-pointer' src={GreenDot} style={{ right: '14rem', top: '25%', width: '14rem', height: '14rem' }} onClick={() => setConfirmReset(true)}></img>}
            </div>}
            <div className={'frame ' + (column ? 'column align-start ' : 'row align-center ') + (optionsClass ? optionsClass + ' ' : '') + (wrap ? 'wrap ' : '')} style={{ gap: '8rem' }}>
                {options.map((option, i) => {
                    const id = (option.id ? parseInt(option.id) + parseInt(idOffset) : i);
                    const isPreselected = preSelected?.find((o) => o.id === option.id);
                    if (preSelected && !showPreSelected && isPreselected) return;
                    const selected = multiSelect ? multiSelected.find((o) => o.id === option.id) : state === id;
                    return <div className='frame row center' style={{ gap: '4rem', padding: '0' }}>
                        {<Option selected={selected} option={option} class={optionClass} styles={optionStyles} addedStyle={addedOptionStyle} handleOnClick={() => {
                            if (isPreselected) return;
                            if (multiSelect) {
                                const newSelected = toggleOption(multiSelected, option, multiSelect, preSelected);
                                console.log('new_selected: ' + JSON.stringify(newSelected, null, 4));
                                setState(newSelected);
                                setMultiSelected(newSelected);
                            }
                            else setState(i);
                            if (confirm) {
                                setSelected(i);
                            }
                            if (select) select(id);
                        }} />}
                        {option.price > 0 && <div className='hover-appear'>
                            <p className='primary semi-bold' style={{ fontSize: optionStyles.default.fontSize }}>+${option.price}</p>
                        </div>}
                    </div>
                })}
            </div>
            {confirm && <div className='frame row justify-end stretch' style={{ gap: '8rem' }}>
                <div className='frame center primary solid hover-pointer' style={{ padding: '8rem 16rem', borderRadius: '16rem' }}
                    onClick={() => {
                        confirm(selected);
                        if (close) close();
                    }}>
                    <p className='bold font-18'>Confirm</p>
                </div>
                {cancel && <div className='frame center primary inverted hover-pointer' style={{ padding: '8rem 16rem', borderRadius: '16rem' }}
                    onClick={() => {
                        cancel();
                        if (close) close();
                    }}>
                    <p className='bold font-18'>Cancel</p>
                </div>}
            </div>}
            {confirmReset &&
                <div className='frame absolute center' style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', gap: '4rem' }}>
                    <div className='frame center primary inverted hover-pointer' style={{ padding: '6rem 12rem', borderRadius: '6rem' }} onClick={() => setConfirmReset(false)}>
                        <p className='font-18'>Cancel</p>
                    </div>
                    <div className='frame center primary hover-pointer' style={{ padding: '6rem 12rem', borderRadius: '6rem' }} onClick={() => {
                        setState(ogState);
                        setConfirmReset(false);
                    }}>
                        <p className='font-18 nowrap'>Reset Change</p>
                    </div>
                </div>}
        </div>
    )
}