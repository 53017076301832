import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../contexts/SessionContext';
import Popup from './Popup';
import Login from './Login';

const RequiresAdmin = ({ children }) => {
    const { session } = useContext(SessionContext);
    const [popup, setPopup] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!session) {
            setPopup('login');
        } else if (!session.is_admin) {
            setPopup('not-admin');
        } else {
            setPopup(null);
        }
    }, [session]);

    const handleClickGoHome = () => {
        navigate('/');
    }

    let popupContent = <></>;

    if (popup === 'login') {
        popupContent = <Popup handleGoBack={() => { }}>
            <div className='frame center column' style={{ gap: '24rem' }}>
                <Login />
            </div>
        </Popup>;
    }

    if (popup === 'not-admin') {
        popupContent = <Popup handleGoBack={() => { }}>
            <div className='frame center column' style={{ gap: '24rem' }}>
                <p className='font-24'>You are not an admin.</p>
                <div className='frame center primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={handleClickGoHome()}>
                    <p className='font-20'>Go Home</p>
                </div>
            </div>
        </Popup>;
    }

    return (
        <>
            {children}
            {popupContent}
        </>
    )
}

export default RequiresAdmin;