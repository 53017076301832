import React, { useEffect, useState } from 'react';

import CheckBox from '../../assets/CheckBox.svg';
import CheckedBox from '../../assets/CheckedBox.svg';
import GreenDot from '../../assets/GreenDot.png';

export default function Checkbox(props) {
    const {
        state, setState,
        onClick,
        className,
        prompt
    } = props;
    return (<div className={className + ' frame hover-pointer'} style={{ gap: '12rem' }} onClick={() => {
        if (onClick) onClick();
        setState(!state);
    }}>
        <img src={state ? CheckedBox : CheckBox} style={{ width: '24rem', height: '24rem' }}></img>
        <p className='font-20'>{prompt}</p>
    </div>);
}

export function EditCheckbox(props) {
    const {
        ogState,
        state,
        setState,
        onClick,
        className,
        prompt
    } = props;

    const changed = ogState !== state;

    return (<div className={className + ' frame hover-pointer relative'} style={{ gap: '12rem', backgroundColor: (changed ? 'var(--light-green)' : 'transparent')  }} onClick={() => {
        if (onClick) onClick();
        setState(!state);
    }}>
        <img src={state ? CheckedBox : CheckBox} style={{ width: '24rem', height: '24rem'}}></img>
        <p className='font-20'>{prompt}</p>
        {changed &&
            <img className='frame absolute' src={GreenDot} style={{ right: '14rem', top: '25%', width: '14rem', height: '14rem' }}></img>}
    </div>);
}