import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { BarLoader, SyncLoader } from 'react-spinners';

export default function CheckoutFinalStep(props) {
    console.log('MADE IT');
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [sameInfo, setSameInfo] = useState(false);
    const [cardholderName, setCardholderName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiration, setExpiration] = useState('');
    const [cvv, setCVV] = useState('');
    const [postalCode, setPostalCode] = useState('');

    useEffect(() => {
        if (!stripe) {
            // console.log('cannot find');
            return;
        }

        const clientSecret = props.clientSecret;

        if (!clientSecret) {
            return;
        }

        setIsLoading(false);

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const onConfirm = async (event) => {
        if (!stripe) {
            // Stripe.js hasn't loaded yet.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setErrorMessage(submitError.message);
            return;
        }

        // Create the PaymentIntent and obtain clientSecret
        const res = await fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/create-intent', {
            method: 'POST',
        });
        const { client_secret: clientSecret } = await res.json();

        // Confirm the PaymentIntent using the details collected by the Express Checkout Element
        const { error } = await stripe.confirmPayment({
            // `elements` instance used to create the Express Checkout Element
            elements,
            // `clientSecret` from the created PaymentIntent
            clientSecret,
            confirmParams: {
                return_url: process.env.REACT_APP_BASE_URL + '/order-complete',
            },
        });

        if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer (for example, payment details incomplete)
            setErrorMessage(error.message);
        } else {
            // The payment UI automatically closes with a success animation.
            // Your customer is redirected to your `return_url`.
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessingPayment(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: process.env.REACT_APP_BASE_URL + '/order-complete',
            },
            receipt_email: props.contactInfo.email
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        // setIsLoading(false);
        setIsProcessingPayment(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <div className='frame column stretch' style={{ gap: '32rem' }}>
            {isLoading ?
                <SyncLoader color='#FF5271' /> :
                <div className='frame column stretch' style={{ gap: '32rem' }}>
                    <form id="payment-form" onSubmit={handleSubmit}>

                        <PaymentElement id="payment-element" options={paymentElementOptions} />
                        {isProcessingPayment ? <BarLoader color='#FF5271' /> : <button disabled={isLoading || !stripe || !elements} id="submit">
                            <p id="button-text">
                                Pay now
                            </p>
                        </button>}
                        {/* Show any error or success messages */}
                        {message && <div id="payment-message">{message}</div>}
                    </form>
                </div>}
        </div>);
}