import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import CartContext from '../contexts/CartContext';

import Selector, { EditSelector } from '../components/Selector';
import Input, { EditInput } from '../components/Input';
import InfoDrawer from '../components/InfoDrawer';
import PhotoInput from '../components/PhotoInput';
import Breadcrumbs from '../components/Breadcrumbs';
import OrderItem from '../components/OrderItem';
import Popup from '../components/Popup';
import Option from '../components/Option';
import StandardOption from '../styles/StandardOption';
import StandardInput from '../styles/StandardInput';
import Logo from '../../assets/Logo1.svg';

import { photoMap3 as photoMap2 } from '../../functions/PhotoMap';
import { getSelectorIDs, getSelectorNames, getSelectorPrice } from '../../functions/SelectorHandler';

// import FullHeart from '../../assets/FullHeart.svg';
// import EmptyHeart from '../../assets/EmptyHeart.svg';
// import OneFourthHeart from '../../assets/OneFourthHeart.svg';
// import HalfHeart from '../../assets/HalfHeart.svg';
// import ThreeFourthHeart from '../../assets/ThreeFourthHeart.svg';
import MinusSign from '../../assets/MinusSign.svg';
import PlusSign from '../../assets/PlusSign.svg';
import Share from '../../assets/Share.svg';
import Trash from '../../assets/Trash.svg';
import Checkbox, { EditCheckbox } from '../components/Checkbox';
import Login from '../components/Login';

const cookies = new Cookies(null, { path: '/' });
// cookies.remove('order', { path: '/' });

function getSecondName(category, defaultSecondName) {
    let newSecondName = '';
    switch (category) {
        case 0:
            newSecondName = 'Chiffon Cake';
            break;
        case 1:
            newSecondName = 'Cake';
            break;
        case 2:
            newSecondName = 'Cupcakes';
            break;
        case 3:
            newSecondName = 'Crinkles';
            break;
        case 4:
            newSecondName = 'Tiramisu';
            break;
        case 5:
            newSecondName = defaultSecondName;
            break;
    }
    return newSecondName;
}

function NewOption(props) {
    const { id, optionName, ogName, ogPrice, ogDescription, ogIngredients, ogShape, ogColor, onBlur, removeOption, isNew } = props;

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [ingredients, setIngredients] = useState('');
    const [shape, setShape] = useState('');
    const [color, setColor] = useState('');

    const [popup, setPopup] = useState(false);

    useEffect(() => {
        setName(ogName);
    }, [ogName]);

    useEffect(() => {
        setPrice(ogPrice);
    }, [ogPrice]);

    useEffect(() => {
        setDescription(ogDescription);
    }, [ogDescription]);

    useEffect(() => {
        setIngredients(ogIngredients);
    }, [ogIngredients]);

    useEffect(() => {
        setShape(ogShape);
    }, [ogShape]);

    useEffect(() => {
        setColor(ogColor);
    }, [ogColor]);

    const optionJson = Object.fromEntries(
        Object.entries({ id, name, price, description, ingredients, shape, color, is_new: isNew }).filter(
            ([_, value]) => value !== undefined && value !== null && value !== ''
        )
    );

    const inputOnBlur = () => {
        // console.log('optionJson:', optionJson);
        if (onBlur) onBlur(optionJson, optionName);
    }

    const handleClickRemoveOption = () => {
        setPopup('remove');
    }

    const handleClickCancelRemove = () => {
        setPopup(false);
    }

    const handleRemoveOption = () => {
        removeOption(id, optionName);
        setPopup(false);
    }

    let overlay = <></>;

    if (popup === 'remove') {
        overlay = <div className='frame absolute stretch center hover-none' style={{ gap: '4rem', width: '100%', height: '100%' }}>
            <div className='frame row center stretch frosted-white' style={{ gap: '8rem', padding: '4rem', width: '100%' }}>
                <div className='frame center primary hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { handleClickCancelRemove() }}>
                    <p className='font-20 frame stretch bold'>Cancel</p>
                </div>
                <div className='frame center primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={handleRemoveOption}>
                    <p className='font-20 frame stretch bold'>Remove {ogName}</p>
                </div>
            </div>
        </div>;
    }

    return (
        <div className='frame row relative' style={{ gap: '12rem', backgroundColor: isNew ? 'var(--light-green)' : 'transparent' }}>
            <div className='frame' style={{ width: '36rem' }}>
                <p className='font-24'>{id}.</p>
            </div>
            <div className='frame column' style={{ gap: '6rem' }}>
                <div className='frame row align-center' style={{ gap: '6rem' }}>
                    <EditInput title={<p className='font-20 semi-bold'>{optionName} Name</p>}
                        ogState={ogName} state={name} setState={setName} inputStyle={StandardInput()} placeholder={optionName + ' Name'}
                        onBlur={inputOnBlur}></EditInput>
                    <EditInput title={<p className='font-20 semi-bold'>{optionName} Price</p>}
                        ogState={ogPrice} state={price} setState={setPrice} inputStyle={StandardInput()} placeholder={optionName + ' Price'}
                        onBlur={inputOnBlur} number></EditInput>
                    {ogShape && <EditInput title={<p className='font-20 semi-bold'>{optionName} Shape</p>}
                        ogState={ogShape} state={shape} setState={setShape} inputStyle={StandardInput()} placeholder={optionName + ' Shape'}
                        onBlur={inputOnBlur}></EditInput>}
                    {(ogColor || ogColor === '') && <EditInput title={<p className='font-20 semi-bold'>{optionName} Color</p>}
                        ogState={ogColor} state={color} setState={setColor} inputStyle={StandardInput()} placeholder={optionName + ' Color'}
                        onBlur={inputOnBlur}></EditInput>}
                </div>
                <div className='frame column align-center' style={{ gap: '6rem' }}>
                    {(ogDescription || ogDescription === '') && <EditInput title={<p className='font-20 semi-bold'>{optionName} Description</p>}
                        ogState={ogDescription} state={description} setState={setDescription} inputStyle={StandardInput()} placeholder={optionName + ' Description'}
                        onBlur={inputOnBlur}></EditInput>}
                    {(ogIngredients || ogIngredients === '') && <EditInput title={<p className='font-20 semi-bold'>{optionName} Ingredients</p>}
                        ogState={ogIngredients} state={ingredients} setState={setIngredients} inputStyle={StandardInput()} placeholder={optionName + ' Ingredients'}
                        onBlur={inputOnBlur}></EditInput>}
                </div>
            </div>
            {removeOption && <img src={Trash} className='hover-pointer' style={{ width: '36rem', height: '36rem', margin: '12rem' }} onClick={handleClickRemoveOption}></img>}
            {overlay}
        </div>
    );
}

function ItemEdit(props) {
    const { handleAddToCart } = useContext(CartContext);
    let { categoryName, itemName } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const toCart = () => {
        navigate('/checkout');
    }

    const [item, setItem] = useState({
        name: '',
        second_name: '',
        url: '',
        category_name: '',
        category_url: '',
        size_options: [],
        flavor_options: [],
        frosting_options: [],
        topping_options: [],
        price_tag: '',
        base_price: 0,
        rating: 5,
        allows_colors: false,
        allows_inscription: false,
        allows_second_flavor: false,
        allows_reference_photos: false,
        thumbnail: '',
        public_id: ''
    });

    const [updatedItem, setUpdatedItem] = useState({
        name: '',
        second_name: '',
        url: '',
        category_id: -1,
        size_options: [],
        flavor_options: [],
        frosting_options: [],
        topping_options: [],
        price_tag: '',
        base_price: 0,
        rating: 5,
        allows_colors: false,
        allows_inscription: false,
        allows_second_flavor: false,
        allows_reference_photos: false,
        thumbnail: '',
        public_id: ''
    });

    const [name, setName] = useState('');
    const [secondName, setSecondName] = useState('');
    const [url, setUrl] = useState(''); //check if url exists
    const [category, setCategory] = useState(0); //needs to fetch list of categories
    const [sizeOptions, setSizeOptions] = useState([]);
    const [flavorOptions, setFlavorOptions] = useState([]);
    const [allowsFrosting, setAllowsFrosting] = useState([]); //needs to fetch list of frosting options
    const [frostingOptions, setFrostingOptions] = useState([]); //needs to fetch list of frosting options
    const [toppingOptions, setToppingOptions] = useState([]); //needs to fetch list of toppings
    const [priceTag, setPriceTag] = useState([]);
    const [basePrice, setBasePrice] = useState(0);
    const [allowsColors, setAllowsColors] = useState(false);
    const [allowsInscription, setAllowsInscription] = useState(false);
    const [allowsSecondFlavor, setAllowsSecondFlavor] = useState(false);
    const [allowsReferencePhotos, setAllowsReferencePhotos] = useState(false);

    const [addedItems, setAddedItems] = useState([]);
    const [removedItems, setRemovedItems] = useState([]);
    const [changedItems, setChangedItems] = useState([]);

    const [popup, setPopup] = useState('login');
    const [confirmError, setConfirmError] = useState('');

    const [update, setUpdate] = useState(0);

    const [newOption, setNewOption] = useState({});
    const [photo, setPhoto] = useState(null);

    useEffect(() => {
        if (itemName === 'create-item') {
            fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/get-categories').then(res => {
                return res.json();
            }).then(res => {
                console.log('category:', res.find(category => category.url === '/' + categoryName).category_id);
                setItem({
                    ...item,
                    categories: res,
                    item_id: -1,
                    category_id: res.find(category => category.url === '/' + categoryName).category_id
                });
                console.log('res:', res);
            });
        } else {
            fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/item-edit?categoryName=' + categoryName + '&itemName=' + itemName).then(res => {
                return res.json();
            }).then(res => {
                if (res.name) setItem(res);
                console.log('res:', res);
            });
        }
    }, []);

    useEffect(() => {
        console.log('item', item);
        setName(item.name);
        setSecondName(item.second_name);
        setBasePrice(item.base_price);
        setPriceTag(item.price_tag);
        setCategory(item.category_id - 1);
        setFlavorOptions(item.flavor_options);
        setSizeOptions(item.size_options);
        setToppingOptions(item.topping_options);
        setFrostingOptions(item.frosting_options);
        setAllowsColors(item.allows_colors);
        setAllowsInscription(item.allows_inscription);
        setAllowsSecondFlavor(item.allows_second_flavor);
        setAllowsReferencePhotos(item.allows_reference_photos);
        setPhoto(item.thumbnail);

        const newUpdatedItem = {
            id: item.id,
            name: item.name,
            second_name: item.second_name,
            url: item.url,
            category_id: item.category_id,
            size_options: item.size_options,
            flavor_options: item.flavor_options,
            frosting_options: item.frosting_options,
            topping_options: item.topping_options,
            price_tag: item.price_tag,
            base_price: item.base_price,
            rating: item.rating,
            allows_colors: item.allows_colors,
            allows_inscription: item.allows_inscription,
            allows_second_flavor: item.allows_second_flavor,
            allows_reference_photos: item.allows_reference_photos,
            thumbnail: item.thumbnail,
            public_id: item.public_id
        }
        setUpdatedItem(newUpdatedItem);
    }, [item]);

    useEffect(() => {
        console.log('category:', category);
        setSecondName(getSecondName(category, item.second_name));
    }, [category]);

    useEffect(() => {
        if (confirmError === '') return;
        const timer = setTimeout(() => {
            setConfirmError('');
        }, 20000);
        return () => clearTimeout(timer);
    }, [confirmError]);

    useEffect(() => {
        const newPriceTag = '$' + basePrice;
        setPriceTag(newPriceTag);
    }, [basePrice]);

    const handleNewOptionChange = (optionName, option) => {
        console.log('option:', option);
        setNewOption({ option, optionName });
    }

    const onNameChange = state => {
        const url = '/' + state.toLowerCase().replace(/ /g, '-');
        const newUpdatedItem = { ...updatedItem, name: state, url };
        setUpdatedItem(newUpdatedItem);
        if (state === item.name) {
            setChangedItems(changedItems.filter(item => item.type !== 'Name'));
        } else if (!changedItems.some(item => item.type === 'Name')) {
            setChangedItems([...changedItems, { type: 'Name', name: state }]);
        } else {
            const newChangedItems = changedItems.map(item => {
                if (item.type === 'Name') {
                    return { type: 'Name', name: state };
                }
                return item;
            });
            setChangedItems(newChangedItems);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const onSecondNameChange = state => {
        const newUpdatedItem = { ...updatedItem, second_name: state };
        setUpdatedItem(newUpdatedItem);
        //check if state is different from the original
        //if it is, add to changedItems
        //if it is already in changedItems, update the value
        if (state === item.second_name) {
            setChangedItems(changedItems.filter(item => item.type !== 'Second Name'));
        } else if (!changedItems.some(item => item.type === 'Second Name')) {
            setChangedItems([...changedItems, { type: 'Second Name', name: state }]);
        } else {
            const newChangedItems = changedItems.map(item => {
                if (item.type === 'Second Name') {
                    return { type: 'Second Name', name: state };
                }
                return item;
            });
            setChangedItems(newChangedItems);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const onBasePriceChange = state => {
        if (state === '') state = 0;
        const newUpdatedItem = { ...updatedItem, base_price: state, price_tag: '$' + state };
        setUpdatedItem(newUpdatedItem);
        //check if state is different from the original
        //if it is, add to changedItems
        //if it is already in changedItems, update the value
        let newChangedItems = [...changedItems];
        if (state === item.base_price) {
            newChangedItems = newChangedItems.filter(item => item.type !== 'Base Price');
        } else if (!newChangedItems.some(item => item.type === 'Base Price')) {
            newChangedItems.push({ type: 'Base Price', name: state });
        }
        //check if price tag is different from the original
        //if it is, add to changedItems
        //if it is already in changedItems, update the value
        if ('$' + state === item.price_tag) {
            newChangedItems = newChangedItems.filter(item => item.type !== 'Price Tag');
        } else if (!newChangedItems.some(item => item.type === 'Price Tag')) {
            newChangedItems.push({ type: 'Price Tag', name: '$' + state });
        }

        setChangedItems(newChangedItems);

        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const onPriceTagChange = state => {
        const newUpdatedItem = { ...updatedItem, price_tag: state };
        setUpdatedItem(newUpdatedItem);
        if (state === item.price_tag) {
            setChangedItems(changedItems.filter(item => item.type !== 'Price Tag'));
        } else if (!changedItems.some(item => item.type === 'Price Tag')) {
            setChangedItems([...changedItems, { type: 'Price Tag', name: state }]);
        } else {
            const newChangedItems = changedItems.map(item => {
                if (item.type === 'Price Tag') {
                    return { type: 'Price Tag', name: state };
                }
                return item;
            });
            setChangedItems(newChangedItems);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const onOptionChange = (option, optionName) => {
        let updatedItemKey;
        switch (optionName) {
            case 'Flavor':
                updatedItemKey = 'flavor_options';
                break;
            case 'Size':
                updatedItemKey = 'size_options';
                break;
            case 'Topping':
                updatedItemKey = 'topping_options';
                break;
            case 'Frosting':
                updatedItemKey = 'frosting_options';
                break;
            default:
                return;
        }

        const newUpdatedItem = {
            ...updatedItem,
            [updatedItemKey]: updatedItem[updatedItemKey].map(opt => opt.id === option.id ? option : opt)
        };
        setUpdatedItem(newUpdatedItem);
        //for each field in option, check if it is different from the original
        //if it is, add to changedItems
        //if it is an added item, update addedItems
        //in form { type: optionName + '-' + id + ' ' + key, name: option[key] }
        const changedFields = Object.keys(option).map(key => {
            const originalItem = item[updatedItemKey].find(item => item.id === option.id);
            if (originalItem && option[key] !== originalItem[key]) {
                return { type: optionName + '-' + option.id + ' ' + key, name: option[key] };
            }
        }).filter(field => field !== undefined);

        console.log('option:', option);
        if (option.is_new) {
            const optionType = optionName;
            console.log('optionType:', optionType);
            const newAddedItems = [...addedItems.filter(addedItem => addedItem.type !== optionType), { type: optionName, name: option.name }];
            setAddedItems(newAddedItems);
        } else {
            const newChangedItems = changedItems.filter(changedItem => {
                const originalItem = item[updatedItemKey].find(item => item.id === option.id);
                return originalItem && !changedFields.some(field => field.type === changedItem.type) && option[changedItem.type.split(' ')[1]] !== originalItem[changedItem.type.split(' ')[1]];
            });
            setChangedItems([...newChangedItems, ...changedFields]);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const onCategoryChange = state => {
        const newUpdatedItem = { ...updatedItem, category_id: state + 1, second_name: getSecondName(state, item.second_name) };
        setUpdatedItem(newUpdatedItem);
        let newChangedItems = [...changedItems];
        if (state + 1 === item.category_id) {
            newChangedItems = newChangedItems.filter(item => item.type !== 'Category');
        } else if (!changedItems.some(item => item.type === 'Category')) {
            newChangedItems.push({ type: 'Category', name: item.categories[state].name });
        } else {
            newChangedItems = newChangedItems.map(changedItem => {
                if (changedItem.type === 'Category') {
                    return { type: 'Category', name: item.categories[state].name };
                }
                return changedItem;
            });
        }

        //check if second name is different from the original
        //if it is, add to changedItems
        //if it is already in changedItems, update the value
        if (getSecondName(state, item.second_name) === item.second_name) {
            newChangedItems = newChangedItems.filter(item => item.type !== 'Second Name');
        } else if (!changedItems.some(item => item.type === 'Second Name')) {
            newChangedItems.push({ type: 'Second Name', name: getSecondName(state, item.second_name) });
        } else {
            newChangedItems = newChangedItems.map(item => {
                if (item.type === 'Second Name') {
                    return { type: 'Second Name', name: getSecondName(state, item.second_name) };
                }
                return item;
            });
        }

        setChangedItems(newChangedItems);
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const removeOption = (id, optionName) => {
        let options, setOptions, updatedItemKey;
        switch (optionName) {
            case 'Flavor':
                options = flavorOptions;
                setOptions = setFlavorOptions;
                updatedItemKey = 'flavor_options';
                break;
            case 'Size':
                options = sizeOptions;
                setOptions = setSizeOptions;
                updatedItemKey = 'size_options';
                break;
            case 'Topping':
                options = toppingOptions;
                setOptions = setToppingOptions;
                updatedItemKey = 'topping_options';
                break;
            case 'Frosting':
                options = frostingOptions;
                setOptions = setFrostingOptions;
                updatedItemKey = 'frosting_options';
                break;
            default:
                return;
        }

        const removedOption = options.find(option => option.id === id);
        const newOptions = options.filter(option => option.id !== id).map((option, index) => {
            option.id = index + 1;
            return option;
        });
        setOptions(newOptions);
        const newUpdatedItem = { ...updatedItem, [updatedItemKey]: newOptions };
        setUpdatedItem(newUpdatedItem);
        if (removedOption.is_new) {
            setAddedItems([...addedItems].filter(item => item.id !== id));
        } else {
            setRemovedItems([...removedItems, { type: optionName, ...removedOption }]);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const handleToggleAllowsInscription = state => {
        setAllowsInscription(state);
        const newUpdatedItem = { ...updatedItem, allows_inscription: state };
        setUpdatedItem(newUpdatedItem);
        state = state ? 'YES' : 'NO';
        if (state === item.allows_inscription) {
            setChangedItems(changedItems.filter(item => item.type !== 'Allows Inscription'));
        } else if (!changedItems.some(item => item.type === 'Allows Inscription')) {
            setChangedItems([...changedItems, { type: 'Allows Inscription', name: state }]);
        } else {
            const newChangedItems = changedItems.map(item => {
                if (item.type === 'Allows Inscription') {
                    return { type: 'Allows Inscription', name: state };
                }
                return item;
            });
            setChangedItems(newChangedItems);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const handleToggleAllowsColors = state => {
        setAllowsColors(state);
        const newUpdatedItem = { ...updatedItem, allows_colors: state };
        setUpdatedItem(newUpdatedItem);
        state = state ? 'YES' : 'NO';
        if (state === item.allows_colors) {
            setChangedItems(changedItems.filter(item => item.type !== 'Allows Colors'));
        } else if (!changedItems.some(item => item.type === 'Allows Colors')) {
            setChangedItems([...changedItems, { type: 'Allows Colors', name: state }]);
        } else {
            const newChangedItems = changedItems.map(item => {
                if (item.type === 'Allows Colors') {
                    return { type: 'Allows Colors', name: state };
                }
                return item;
            });
            setChangedItems(newChangedItems);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const handleToggleAllowsReferencePhotos = state => {
        setAllowsReferencePhotos(state);
        const newUpdatedItem = { ...updatedItem, allows_reference_photos: state };
        setUpdatedItem(newUpdatedItem);
        state = state ? 'YES' : 'NO';
        if (state === item.allows_reference_photos) {
            setChangedItems([...changedItems.filter(item => item.type !== 'Allows Reference Photos')]);
        } else if (!changedItems.some(item => item.type === 'Allows Reference Photos')) {
            setChangedItems([...changedItems, { type: 'Allows Reference Photos', name: state }]);
        } else {
            const newChangedItems = changedItems.map(item => {
                if (item.type === 'Allows Reference Photos') {
                    return { type: 'Allows Reference Photos', name: state };
                }
                return item;
            });
            setChangedItems([...newChangedItems]);
        }
        console.log('newUpdatedItem:', newUpdatedItem);
    }

    const handleClickNewFlavor = () => {
        setPopup('new-flavor');
    }

    const handleClickNewSize = () => {
        setPopup('new-size');
    }

    const handleClickNewTopping = () => {
        setPopup('new-topping');
    }

    const handleClickNewFrosting = () => {
        setPopup('new-frosting');
    }

    const handleConfirmNewOption = () => {
        console.log('option:', newOption);
        const option = { ...newOption.option, is_new: true };
        switch (newOption.optionName) {
            case 'Flavor':
                const newFlavorOptions = [...flavorOptions, option];
                setFlavorOptions(newFlavorOptions);
                const newUpdatedItem = { ...updatedItem, flavor_options: newFlavorOptions };
                setUpdatedItem(newUpdatedItem);
                setAddedItems([...addedItems, { type: 'Flavor', ...option }]);
                console.log('newUpdatedItem:', newUpdatedItem);
                break;
            case 'Size':
                const newSizeOptions = [...sizeOptions, option];
                setSizeOptions(newSizeOptions);
                const newUpdatedItem2 = { ...updatedItem, size_options: newSizeOptions };
                setUpdatedItem(newUpdatedItem2);
                setAddedItems([...addedItems, { type: 'Size', ...option }]);
                console.log('newUpdatedItem:', newUpdatedItem2);
                break;
            case 'Topping':
                const newToppingOptions = [...toppingOptions, option];
                setToppingOptions(newToppingOptions);
                const newUpdatedItem3 = { ...updatedItem, topping_options: newToppingOptions };
                setUpdatedItem(newUpdatedItem3);
                setAddedItems([...addedItems, { type: 'Topping', ...option }]);
                console.log('newUpdatedItem:', newUpdatedItem3);
                break;
            case 'Frosting':
                const newFrostingOptions = [...frostingOptions, option];
                setFrostingOptions(newFrostingOptions);
                const newUpdatedItem4 = { ...updatedItem, frosting_options: newFrostingOptions };
                setUpdatedItem(newUpdatedItem4);
                setAddedItems([...addedItems, { type: 'Frosting', ...option }]);
                console.log('newUpdatedItem:', newUpdatedItem4);
                break;
        }
        setPopup(false);
    }

    const handleClickSaveChanges = () => {
        setPopup('confirm');
    }

    const handleConfirmSaveChanges = async () => {
        if (itemName === 'create-item') {
            // Validate required fields
            const requiredFields = {
                name: updatedItem.name,
                second_name: updatedItem.second_name,
                category_id: updatedItem.category_id,
                base_price: updatedItem.base_price,
                price_tag: updatedItem.price_tag,
                photo: photo
            };

            const missingFields = Object.entries(requiredFields)
                .filter(([_, value]) => !value)
                .map(([key]) => key);

            if (missingFields.length > 0) {
                setConfirmError(`Please fill in required fields: ${missingFields.join(', ')}`);
                return;
            }

            //for each field in updatedItem that is an array, remove the "is_new" field
            const newItem = {
                ...updatedItem,
                size_options: updatedItem.size_options.map(option => {
                    const { is_new, ...newOption } = option;
                    return newOption;
                }),
                flavor_options: updatedItem.flavor_options.map(option => {
                    const { is_new, ...newOption } = option;
                    return newOption;
                }),
                frosting_options: updatedItem.frosting_options.map(option => {
                    const { is_new, ...newOption } = option;
                    return newOption;
                }),
                topping_options: updatedItem.topping_options.map(option => {
                    const { is_new, ...newOption } = option;
                    return newOption;
                })
            };
            // Send to create-item endpoint
            fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/create-item', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    newItem,
                    photo
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                console.log('res:', res);
                // Clear localStorage and state
                localStorage.removeItem(`photo_${item.id}`);
                URL.revokeObjectURL(photo);
                setPhoto(null);
                return res;
            }).then(() => {
                //navigate to menu-edit/category
                navigate('/menu-edit' + item.categories.find(category => category.category_id === updatedItem.category_id).url);
            }).catch(error => {
                console.error('Error:', error);
                setPopup('error');
            });
        } else {
            // Existing edit-item logic
            fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/edit-item', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    updatedItem,
                    photo
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                console.log('res:', res);
                setItem(res);
                localStorage.removeItem(`photo_${item.id}`);
                URL.revokeObjectURL(photo);
                setPhoto(null);
                setPopup('saved');
            }).catch(error => {
                console.error('Error:', error);
                setPopup('error');
            });
        }
    };

    return (
        <div className='frame column align-start stretch flex-100 true-white' style={{ gap: '32rem', padding: '32rem 80rem' }}>
            <Breadcrumbs routes={[
                {
                    url: '/menu-edit',
                    name: 'menu'
                },
                {
                    url: '/menu-edit' + (item.category_url ? item.category_url : '...'),
                    name: (item.category_name ? item.category_name : '...')
                },
                {
                    url: '/menu-edit' + item.url,
                    name: item.name + ' ' + item.second_name
                }
            ]} />
            <div className='frame row justify-between stretch' style={{ gap: '40rem' }}>
                <div className='frame column justify-between'>
                    <div className='frame justify-center stretch' style={{ padding: '32rem 0' }}>
                        <div className='frame align-end primary-gradient stretch' style={{ gap: '12rem', margin: '0 30rem', padding: '60rem', height: '30vw', width: '25vw', borderTopLeftRadius: '50%', borderTopRightRadius: '50%' }}>
                            <img src={photoMap2.get(item.category_url + item.url)} style={{ width: '100%' }}></img>
                        </div>
                    </div>
                    {/* <Reviews></Reviews> */}
                </div>
                {/* Header: Category, Second Name, Name, Price */}
                <div className='frame column stretch flex-100' style={{ gap: '24rem' }}>
                    <div className='frame column'>
                        <div className='frame column stretch'>
                            {item.categories && <EditSelector
                                title={<p className='font-20 semi-bold'>Item Category</p>}
                                options={item.categories}
                                ogState={item.category_id - 1}
                                state={category}
                                setState={setCategory}
                                select={onCategoryChange}
                                optionStyles={StandardOption()}
                                selectorStyle={{ gap: '12rem' }} column />}
                            <EditInput title={<p className='font-20 semi-bold'>Item Category Name</p>}
                                ogState={item.second_name} state={secondName} setState={setSecondName} inputStyle={StandardInput()} placeholder='Category Name'
                                onBlur={onSecondNameChange}></EditInput>
                            <div className='frame row stretch justify-between' style={{ gap: '12rem' }}>
                                <EditInput title={<p className='font-20 semi-bold'>Item Name/Flavor</p>}
                                    ogState={item.name} state={name} setState={setName} inputStyle={StandardInput()} placeholder='Item Name/Flavor'
                                    onBlur={onNameChange}></EditInput>
                                <EditInput title={<p className='font-20 semi-bold'>Item Base Price (Round down to nearest whole number)</p>}
                                    ogState={item.base_price} state={basePrice} setState={setBasePrice} inputStyle={StandardInput()} placeholder='Item Base Price'
                                    onBlur={onBasePriceChange} number></EditInput>
                            </div>
                            <EditInput title={<p className='font-20 semi-bold'>Item Price Tag</p>}
                                ogState={item.price_tag} state={priceTag} setState={setPriceTag} inputStyle={StandardInput()} placeholder='Item Price Tag'
                                onBlur={onPriceTagChange}></EditInput>
                        </div>
                    </div>
                    <div className='frame column stretch'>
                        <PhotoInput
                            id={item.id}
                            title="Item Photo"
                            state={photo || (photoMap2.get(item.url) || '')}
                            setState={setPhoto}
                            inputStyle={StandardInput()}
                        />
                    </div>
                    <div className='row-divider-1'></div>
                    {/* Flavor */}
                    {/* Row needs name, price, description, ingredients, remove */}
                    {/* Add button */}
                    <InfoDrawer title={<p className='font-20 semi-bold'>Item Flavors</p>}>
                        <div className='frame column' style={{ gap: '24rem' }}>
                            {flavorOptions.map(option => {
                                return <NewOption id={option.id} optionName='Flavor' ogName={option.name} ogPrice={option.price}
                                    ogDescription={option.description} ogIngredients={option.ingredients} onBlur={onOptionChange} removeOption={removeOption}
                                    isNew={option.is_new} />
                            })}
                            <Option styles={StandardOption()} option={{ name: 'Add New Flavor' }} handleOnClick={handleClickNewFlavor}></Option>
                        </div>
                    </InfoDrawer>
                    <div className='row-divider-1'></div>
                    {/* Sizes */}
                    {/* Row needs name, price, description, ingredients, remove */}
                    {/* Add button */}
                    <InfoDrawer title={<p className='font-20 semi-bold'>Item Sizes</p>}>
                        <div className='frame column' style={{ gap: '24rem' }}>
                            {sizeOptions.map(option => {
                                return <NewOption id={option.id} optionName='Size' ogName={option.name} ogPrice={option.price} onBlur={onOptionChange} removeOption={removeOption}
                                    isNew={option.is_new} />
                            })}
                            <Option styles={StandardOption()} option={{ name: 'Add New Size' }} handleOnClick={handleClickNewSize}></Option>
                        </div>
                    </InfoDrawer>
                    <div className='row-divider-1'></div>
                    {/* Toppings */}
                    {/* Row needs name, price, description, ingredients, remove */}
                    {/* Add button */}
                    <InfoDrawer title={<p className='font-20 semi-bold'>Item Toppings</p>}>
                        <div className='frame column' style={{ gap: '24rem' }}>
                            {toppingOptions.map(option => {
                                return <NewOption id={option.id} optionName='Topping' ogName={option.name} ogPrice={option.price} ogColor={option.color ? option.color : ''}
                                    ogDescription={option.description} ogIngredients={option.ingredients} onBlur={onOptionChange} removeOption={removeOption}
                                    isNew={option.is_new} />
                            })}
                            <Option styles={StandardOption()} option={{ name: 'Add New Topping' }} handleOnClick={handleClickNewTopping}></Option>
                        </div>
                    </InfoDrawer>
                    {/* Frostings */}
                    {/* Row needs name, price, description, ingredients, remove */}
                    {/* Add button */}
                    <div className='row-divider-1'></div>
                    {/* <EditCheckbox ogState={item.has_frosting_choice} state={allowsFrosting} setState={setAllowsFrosting} prompt='Allow customers to choose frosting'></Checkbox> */}
                    {category === 1 && <>
                        <InfoDrawer title={<p className='font-20 semi-bold'>Item Frostings</p>}>
                            <div className='frame column' style={{ gap: '24rem' }}>
                                {frostingOptions.map(option => {
                                    return <NewOption id={option.id} optionName='Frosting' ogName={option.name} ogPrice={option.price}
                                        ogDescription={option.description} ogIngredients={option.ingredients} onBlur={onOptionChange} removeOption={removeOption}
                                        isNew={option.is_new} />
                                })}
                                <Option styles={StandardOption()} option={{ name: 'Add New Frosting' }} handleOnClick={handleClickNewFrosting}></Option>
                            </div>
                        </InfoDrawer>
                        <div className='row-divider-1'></div>
                    </>
                    }
                    <EditCheckbox ogState={item.allows_inscription} state={allowsInscription} setState={handleToggleAllowsInscription} prompt='Allow customers to add writing'></EditCheckbox>
                    <div className='row-divider-1'></div>
                    <EditCheckbox ogState={item.allows_colors} state={allowsColors} setState={handleToggleAllowsColors} prompt='Allow customers to specify colors'></EditCheckbox>
                    <div className='row-divider-1'></div>
                    <EditCheckbox ogState={item.allows_reference_photos} state={allowsReferencePhotos} setState={handleToggleAllowsReferencePhotos} prompt='Allow customers to add reference photos'></EditCheckbox>
                    <div className='row-divider-1'></div>
                    <div className='frame column stretch'>
                        <div className='frame row center stretch' style={{ gap: '16rem' }}>
                            {/* <div className='frame center hover-pointer' style={{ gap: '16rem', padding: '18rem 24rem', borderRadius: '10rem', border: '2.5rem solid var(--lightest-grey)' }} onClick={handleClickPreview}>
                                <p className='bold font-20'>Preview</p>
                            </div> */}
                            <div className='frame center green stretch flex-100 hover-pointer' style={{ gap: '16rem', padding: '18rem 24rem', borderRadius: '10rem', border: '2.5rem solid var(--green)' }}
                                onClick={handleClickSaveChanges}>
                                <p className='bold font-24'>Save Changes</p>
                            </div>
                        </div>
                        {confirmError.length > 0 &&
                            <p className='font-20 primary'>{confirmError}</p>
                        }
                    </div>
                    {item.category_name.includes('Cake') &&
                        <>
                            <div className='row-divider-1'></div>
                            <InfoDrawer title={<p className='font-24 semi-bold'>How to preserve cakes</p>}>
                                <p className='stretch font-20 light-grey'>
                                    <span className='black'>To ensure cake safety and preservation, we recommend...</span>
                                    <br />
                                    <ul>
                                        <li>Placing the cake on the <span className='primary'>car floor with A/C</span> during transit</li>
                                        <br />
                                        <li>Keeping your cake <span className='primary'>refrigerated</span> if your cake has whipped, cream frosting, fresh fruit, or mascarpone. Take your cake out of the fridge <span className='primary'>right before serving</span>.</li>
                                        <br />
                                        <li>Storing cakes with buttercream icing and no perishables as listed above outside the fridge at <span className='primary'>room temperature</span> to preserve moisture and softness.</li>
                                    </ul>
                                    Chefska’s Cravings assumes no liability for damages or accidents occurring after pickup. <span className='dark-grey'>Additional charges may be incurred upon return and modifications.</span></p>
                            </InfoDrawer>
                        </>
                    }
                    <div className='row-divider-1'></div>
                    <InfoDrawer title={<p className='font-24 semi-bold'>Terms & Conditions</p>}>
                        <p className='stretch font-20 light-grey'>
                            <span className='bold'>All orders must be placed at least 5 days in advance for pickup only. If you need it before this time, please contact us <span className='primary'>chefskascravings@gmail.com</span>.</span>
                            <br /><br />
                            No refunds for orders orders canceled 24 hours before pickup.
                            <br /><br />
                            50% refund for orders canceled more than 24 hours before pickup.
                            <br /><br />
                            Orders can be rescheduled 48 hours before pickup time.
                            <br /><br />
                            Cake artist can make any modification that she considers necessary on the decoration.
                            <br /><br />
                            Seasonal ingredients are subject to change, and we reserve the right to make reasonable substitutions while ensuring the overall quality and flavor profile of our products.
                            <br /><br />
                            For darker colors, bold colors are not available for whipped cream.
                            <br /><br />
                            Customers with allergies or dietary restrictions should review our ingredient lists and inform us of any special requirements before placing an order. We will do our best to accommodate your needs. We do not offer gluten-free at the moment.
                            <br /><br />
                            Certain cakes may require items for decoration and stability. Natural flowers and any ornamental items on cakes are for decoration only and should not be consumed.
                            <br /><br />
                            Portions estimated per cake will depend on the size of the slices when cutting the cake.
                            <br /><br />
                            Chefska’s Cravings assumes no liability for damages or accidents occurring after pickup. Additional charges may be incurred upon return and modifications.
                            <br /><br />
                            <span className='bold'>By placing the order you are accepting all terms and conditions.</span>
                        </p>
                    </InfoDrawer>
                    <div className='row-divider-1'></div>
                </div>
            </div>
            {popup === 'confirm' &&
                <Popup handleGoBack={() => setPopup(false)}>
                    <div className='frame center column' style={{ gap: '16rem' }}>
                        <p className='font-24 bold'>Are you sure you want to save changes?</p>
                        {/* Show changes here by comparing updatedItem to item */}
                        <div className='frame column' style={{ gap: '16rem' }}>
                            {addedItems.length > 0 && (
                                <div>
                                    <p className='font-20 bold'>Added:</p>
                                    {addedItems.map((item, index) => (
                                        <p key={index} className='font-18'>{item.type} - {item.name}</p>
                                    ))}
                                </div>
                            )}
                            {removedItems.length > 0 && (
                                <div>
                                    <p className='font-20 bold'>Removed:</p>
                                    {removedItems.map((item, index) => (
                                        <p key={index} className='font-18'>{item.type} - {item.name}</p>
                                    ))}
                                </div>
                            )}
                            {changedItems.length > 0 && (
                                <div>
                                    <p className='font-20 bold'>Changed:</p>
                                    {changedItems.map((item, index) => (
                                        <p key={index} className='font-18'>{item.type} - {item.name}</p>
                                    ))}
                                </div>
                            )}
                            {photo && photo !== '' && (
                                <div>
                                    <p className='font-20 bold'>Photo changed.</p>
                                </div>
                            )}
                            {addedItems.length === 0 && removedItems.length === 0 && changedItems.length === 0 && (!photo || photo === '') && (
                                <p className='font-18'>No changes made.</p>
                            )}
                        </div>
                    </div>
                    <div className='frame center' style={{ gap: '16rem' }}>
                        <div className='frame primary inverted hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                            onClick={() => setPopup(false)}>
                            <p className='bold font-24'>Go Back</p>
                        </div>
                        {!(addedItems.length === 0 && removedItems.length === 0 && changedItems.length === 0 && (!photo || photo === '')) && (
                            <div className='frame primary hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                                onClick={handleConfirmSaveChanges}>
                                <p className='bold font-24'>Save Changes</p>
                            </div>
                        )}
                    </div>
                </Popup>
            }
            {popup && popup.startsWith('new') &&
                <Popup handleGoBack={() => setPopup(false)}>
                    <div className='frame center column' style={{ gap: '16rem' }}>
                        {popup === 'new-flavor' &&
                            <NewOption id={flavorOptions.length + 1} optionName='Flavor' ogName={''} ogPrice={0}
                                ogDescription={''} ogIngredients={''} onBlur={option => {
                                    handleNewOptionChange('Flavor', option);
                                }} />
                        }
                        {popup === 'new-size' &&
                            <NewOption id={sizeOptions.length + 1} optionName='Size' ogName={''} ogPrice={0} onBlur={option => {
                                handleNewOptionChange('Size', option);
                            }} />
                        }
                        {popup === 'new-topping' &&
                            <NewOption id={toppingOptions.length + 1} optionName='Topping' ogName={''} ogColor={''} ogPrice={0} onBlur={option => {
                                handleNewOptionChange('Topping', option);
                            }} />
                        }
                        {popup === 'new-frosting' &&
                            <NewOption id={frostingOptions.length + 1} optionName='Frosting' ogName={''} ogPrice={0} onBlur={option => {
                                handleNewOptionChange('Frosting', option);
                            }} />
                        }
                        <div className='frame row center stretch' style={{ gap: '16rem' }}>
                            <div className='frame primary inverted hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                                onClick={() => setPopup(false)}>
                                <p className='bold font-24'>Cancel</p>
                            </div>
                            <div className='frame primary hover-pointer center flex-100' style={{ padding: '8rem 16rem', borderRadius: '10rem' }}
                                onClick={handleConfirmNewOption}>
                                <p className='bold font-24'>Add {newOption.name}</p>
                            </div>
                        </div>
                    </div>
                </Popup>
            }
        </div>
    );
}

export default ItemEdit;