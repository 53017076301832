import React, { useState, useContext } from 'react';
import Input from './Input';
import Checkbox from './Checkbox';
import SessionContext from '../contexts/SessionContext';

export default function Login() {
    const { handleLogin, handleSignUp } = useContext(SessionContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [signUp, setSignUp] = useState(false);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePassword = (password) => {
        const errors = [];
        // const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        // check each condition, if it doesn't pass, add to errors
        if (password.length < 8) {
            errors.push("Password must be at least 8 characters long");
        }
        if (!password.match(/[a-z]/)) {
            errors.push("Password must contain at least one lowercase letter");
        }
        if (!password.match(/[A-Z]/)) {
            errors.push("Password must contain at least one uppercase letter");
        }
        if (!password.match(/\d/)) {
            errors.push("Password must contain at least one number");
        }
        if (!password.match(/[@$!%*?&]/)) {
            errors.push("Password must contain at least one special character");
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError('Please fill in all fields');
            return;
        }

        if (signUp) {
            if (!validateEmail(email)) {
                setError('Invalid email address');
                return;
            }
            const errors = validatePassword(password);
            if (errors.length > 0) {
                setError(errors);
                return;
            }
            if (password !== confirmPassword) {
                setError('Passwords do not match');
                return;
            }
            if (!firstName || !lastName || !phoneNumber) {
                setError('Please fill in all fields');
                return;
            }

            const errorMessage = await handleSignUp(firstName, lastName, email, phoneNumber, password);
            if (errorMessage) {
                setError(errorMessage);
            }
            return;
        }

        const errorMessage = await handleLogin(email, password);
        if (errorMessage) {
            setError(errorMessage);
        }
    };

    return (
        <form className="frame column center" onSubmit={handleSubmit} style={{ gap: '24rem', width: '400rem' }}>
            <h2 className='font-32'>Please Log In</h2>
            <Input
                state={email}
                setState={setEmail}
                placeholder="Email"
                type="email"
                inputStyle={{ default: {}, selected: {} }}
                className="input"
            />
            {signUp &&
                <>
                    <Input
                        state={firstName}
                        setState={setFirstName}
                        placeholder="First Name"
                        type="text"
                        inputStyle={{ default: {}, selected: {} }}
                        className="input"
                    />
                    <Input
                        state={lastName}
                        setState={setLastName}
                        placeholder="Last Name"
                        type="text"
                        inputStyle={{ default: {}, selected: {} }}
                        className="input"
                    />
                    <Input
                        state={phoneNumber}
                        setState={setPhoneNumber}
                        placeholder="Phone Number"
                        type="tel"
                        inputStyle={{ default: {}, selected: {} }}
                        className="input"
                    />
                </>
            }
            <Input
                state={password}
                setState={setPassword}
                placeholder="Password"
                type="password"
                inputStyle={{ default: {}, selected: {} }}
                className="input"
            />
            {signUp &&
                <Input
                    state={confirmPassword}
                    setState={setConfirmPassword}
                    placeholder="Confirm Password"
                    type="password"
                    inputStyle={{ default: {}, selected: {} }}
                    className="input"
                />}
            {/* {!signUp && <Checkbox
                state={rememberMe}
                setState={setRememberMe}
                prompt="Remember Me"
                className="checkbox"
            />} */}
            {/* check if error is an array */}
            {error && Array.isArray(error) ?
                <div className='frame column center' style={{ gap: '8rem' }}>
                    {error.map((err, i) => <p key={i} className="font-16 primary">{err}</p>)}
                </div>
                : <p className="font-16 primary">{error}</p>}
            <button type="submit" className="frame center primary hover-pointer" style={{ padding: '12rem 24rem', borderRadius: '6rem' }}>
                <p className="font-20">{signUp ? 'Sign Up' : 'Login'}</p>
            </button>
            {signUp ?
                <p className='font-20'>Already have an account?</p> :
                <p className='font-20'>Don't have an account?</p>
            }
            <div className="frame center primary inverted hover-pointer" style={{ padding: '12rem 24rem', borderRadius: '6rem' }} onClick={() => setSignUp(!signUp)}>
                <p className="font-20 bold">{signUp ? 'Login' : 'Sign Up'}</p>
            </div>
        </form>
    );
}