import React from 'react';

import Redirect from './Redirect';

import { photoMap3 as photoMap } from '../../functions/PhotoMap';
import Logo from '../../assets/Logo1.svg';
import MenuItemBackground from '../../assets/MenuItemBackground.jpg';

function MenuItem(props) {
    let parsedURL = props.url.split('/');
    return (
        <Redirect to={'/menu' + props.url}>
            <div className='frame center stretch column' style={{ gap: '8rem', padding: '0' }}>
                <div className='frame relative center' style={{ height: '150rem', width: '168rem' }}>
                    <img src={MenuItemBackground} className='frame absolute'
                        style={{ height: '150rem', width: '168rem', borderRadius: '24rem' }}></img>
                    <img src={props.thumbnail || (photoMap.get(props.url) ? photoMap.get(props.url) : Logo)} className='frame item-image absolute'
                        style={{ height: '140rem', width: '150rem' }}></img>
                </div>
                <div className='frame column center stretch flex-100' style={{ gap: '4rem' }}>
                    <p className='font-14 stretch center bold' style={{ width: '125rem' }}>{props.name}</p>
                    <p className='font-14 faded-grey center stretch'>{props.price}</p>
                </div>
            </div>
            {/* <Frame className={props.className + ' menu-item center stretch'} height={props.height} gap='9rem'>
                <Frame className='column center' padding='29rem 0rem'>
                    <img src={photoMap.get(props.url)} className={photoMap.get(props.url) ? '' : 'item-image ' + props.flavor} style={{ height: '232rem', width: '260rem' }}></img>
                    <Frame className='item-info column center stretch' gap='14.5rem' padding='0rem 0rem 0rem 30rem'>
                        <Frame className='center stretch' gap='3.5rem'>
                            <Frame className='align-start flex-100' gap='3.5rem'>
                                <Frame className='column align-items-start flex-100' gap='3.5rem'>
                                    <p className='menu-item-name stretch bold'>{props.name}</p>
                                    <p className='menu-item-price stretch'>{props.price}</p>
                                </Frame>
                            </Frame>
                        </Frame>
                    </Frame>
                </Frame>
            </Frame> */}
        </Redirect>
    );
}

export default MenuItem;