import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link, Element, scroller } from 'react-scroll';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { GridLoader } from 'react-spinners';

import Redirect from '../mobileComponents/Redirect';
import MenuItem from '../mobileComponents/MenuItem';

import FeaturedBackground from '../../assets/FeaturedBackground.png';
import MangoChiffonSlice from '../../photos/MangoChiffonSlice.png';

function Menu(props) {
    let { categoryName } = useParams();
    const [menu, setMenu] = useState([]);

    const { rem } = useWindowDimensions();

    /**
     * [
     *  {
     *      category:
     *      url:
     *      items: [
     *          {
     *              name:
     *              url:
     *              thumbnail:
     *              price_tag:
     *              price:
     *          }
     *      ]
     *  }
     * ]
     */
    useEffect(() => {
        // get all items
        console.log('API - ' + process.env.REACT_APP_API_URL + '/.netlify/functions/menu');
        fetch(process.env.REACT_APP_API_URL + '/.netlify/functions/menu')
            .then(res => {
                return res.json();
            })
            .then(res => {
                console.log('fetch: ' + JSON.stringify(res));
                setMenu(res);
            });
    }, []);

    useEffect(() => {
        if (categoryName) {
            scroller.scrollTo('/' + categoryName, {
                smooth: true,
                duration: 1000,
                // offset: -98 * rem
            });
        } else {
            scroller.scrollTo('/', {
                smooth: true,
                duration: 1000,
                // offset: -98 * rem
            });
        }
    }, [menu, categoryName]);

    const sidebarCategoryFrames = [];
    const categoryFrames = [];
    console.log(menu);
    for (const category of menu) {
        console.log('category: ', category);
        sidebarCategoryFrames.push(<Link activeClass='selected-category' to={category.url} spy={true} smooth={true} duration={350} offset={-98 * rem}>
            <p className='font-20 hover-pointer bold-hover' name={category.category}>{category.category}</p>
        </Link>);
        categoryFrames.push(<Element name={category.url}>
            <div className='frame column align-start stretch' id={category.url} style={{ gap: '16rem', padding: '32rem 0 0 0' }}>
                <p className='font-32 primary bold'>{category.category}</p>
                <div className='frame align-start-start stretch wrap' style={{ gap: '8rem' }}>
                    {category.items.map((item, i) => <MenuItem name={item.name} price={item.price_tag} flavor={item.flavor} url={category.url + item.url} thumbnail={item.thumbnail}></MenuItem>)}
                </div>
            </div>
        </Element>);
    }

    return (
        <Element name='/'>
            <div id='/' className='frame column align-start stretch flex-100 offwhite' style={{ gap: '0rem', padding: '85rem 0' }}>
                <div className='frame column align-start stretch' style={{ gap: '10rem', padding: '8rem 28rem' }}>
                    <div className='frame align-start stretch' style={{ gap: '8rem', padding: '8rem 8rem 0rem 8rem' }}>
                        <p className='casual font-40 primary'>Menu</p>
                    </div>
                    <div className='frame center stretch' style={{ padding: '8rem' }}>
                        <p className='font-16'>
                            <span className='italic'>Go, eat your food with gladness, and drink your wine with a joyful heart, for God has already approved what you do.</span>
                            <br />
                            <span className='primary'>Ecclesiastes 9:7</span>
                        </p>
                    </div>
                    {/* <Frame className='best-sellers'></Frame> */}
                </div>
                {menu.length > 0 ?
                    <div className='frame column justify-end stretch' style={{ gap: '16rem', padding: '0rem 28rem' }}>
                        {/* <Redirect to='/menu/seasonal-sweets/mango'>
                            <div className='frame column justify-center align-start stretch primary' style={{ gap: '16rem', padding: '32rem', borderRadius: '20rem' }}>
                                <div className='frame column justify-center align-start stretch'>
                                    <p className='font-18 uppercase'>Featured</p>
                                    <p className='font-24 bold'>Mango Chiffon Cake</p>
                                </div>
                                <div className='frame column center relative' style={{ width: '250rem', height: '250rem', padding: 'auto' }}>
                                    <img src={FeaturedBackground} className='absolute' style={{ width: '250rem', objectFit: 'contain' }}></img>
                                    <img src={MangoChiffonSlice} className='absolute' style={{ width: '250rem', objectFit: 'contain' }}></img>
                                </div>
                                <p className='frame stretch font-18 bold align-self-end'>Last month to order!</p>
                            </div>
                        </Redirect> */}
                        {categoryFrames}
                    </div> :
                    <div className='frame column justify-end stretch' style={{ gap: '16rem', padding: '0rem 28rem' }}>
                        <GridLoader color='#FF5271' />
                    </div>
                }
            </div>
        </Element>
    );
}

export default Menu;