import React, { useEffect, useState } from 'react';

import Redirect from './Redirect';

import GreenDot from '../../assets/GreenDot.png';

import { photoMap } from '../../functions/PhotoMap';

/**
 * 
 * @param {*} props 
 * @returns 
 * 
 * states: default, options, change availability
 * default -> status: available, out-season, archived, coming soon
 * options: change availability, edit
 * change availability: available, out-season, archived, coming soon
 */
function MenuItemEdit(props) {
    const {
        update, id, name, price, flavor, url, status, addChange, removeChange, editOnClick
    } = props;

    let parsedURL = props.url.split('/');

    const [state, setState] = useState('default');
    const [statusChange, setStatusChange] = useState(status);

    useEffect(() => {
        setStatusChange(status);
    }, [update]);

    const onClick = () => {
        if (state === 'default') {
            setState('options');
        }
    }

    const backOnClick = () => {
        setState('default');
    }

    const changeAvailabilityOnClick = () => {
        setState('availability options');
    }

    const setAvailabilityOnClick = (newStatus) => {
        //stage changes in higher component
        //needs item id
        if (status === newStatus)
            removeChange('set-status', id, newStatus);
        else
            addChange('set-status', id, newStatus);
        setStatusChange(newStatus);
        setState('default');
    }

    let overlay = <></>;

    switch (statusChange) {
        case 'outseason':
            overlay = <div className='frame absolute stretch center hover-none' style={{ gap: '4rem', padding: '8rem', width: '100%', height: '100%' }}>
                <div className='frame column center stretch frosted-white' style={{ gap: '8rem', padding: '4rem', width: '100%' }}>
                    <div className='frame center stretch hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { changeAvailabilityOnClick(); }}>
                        <p className='font-20 frame stretch bold'>Out of Season</p>
                    </div>
                </div>
            </div>;
            break;
        case 'archived':
            overlay = <div className='frame absolute stretch center hover-none' style={{ gap: '4rem', padding: '8rem', width: '100%', height: '100%' }}>
                <div className='frame column center stretch frosted-white' style={{ gap: '8rem', padding: '4rem', width: '100%' }}>
                    <div className='frame center stretch hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { changeAvailabilityOnClick(); }}>
                        <p className='font-20 frame stretch bold'>Archived</p>
                    </div>
                </div>
            </div>;
            break;
        case 'sold':
            overlay = <div className='frame absolute stretch center hover-none' style={{ gap: '4rem', padding: '8rem', width: '100%', height: '100%' }}>
                <div className='frame column center stretch frosted-white' style={{ gap: '8rem', padding: '4rem', width: '100%' }}>
                    <div className='frame center stretch hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { changeAvailabilityOnClick(); }}>
                        <p className='font-20 frame stretch bold'>Sold Out</p>
                    </div>
                </div>
            </div>;
            break;
    }

    switch (state) {
        case 'options':
            if (id === 0) {
                overlay = <div className='frame absolute stretch center hover-none' style={{ gap: '4rem', padding: '8rem', width: '100%', height: '100%' }}>
                    <div className='frame column center stretch frosted-white' style={{ gap: '8rem', padding: '4rem', width: '100%' }}>
                        {/* <Redirect to={'/menu-edit' + props.url} className='stretch'> */}
                            <div className='frame center stretch primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => editOnClick(url)}>
                                <p className='font-20 frame stretch bold'>Start</p>
                            </div>
                        {/* </Redirect> */}
                        <div className='frame center primary hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { backOnClick() }}>
                            <p className='font-20 frame stretch bold'>Cancel</p>
                        </div>
                    </div>
                </div>;
                break;
            }
            overlay = <div className='frame absolute stretch center hover-none' style={{ gap: '4rem', padding: '8rem', width: '100%', height: '100%' }}>
                <div className='frame column center stretch frosted-white' style={{ gap: '8rem', padding: '4rem', width: '100%' }}>
                    <div className='frame center stretch primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { changeAvailabilityOnClick(); }}>
                        <p className='font-20 frame stretch bold'>Change Availability</p>
                    </div>
                    {/* <Redirect to={'/menu-edit' + props.url} className='stretch'> */}
                        <div className='frame center stretch primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { editOnClick(url); }}>
                            <p className='font-20 frame stretch bold'>Edit</p>
                        </div>
                    {/* </Redirect> */}
                    <div className='frame center primary hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { backOnClick() }}>
                        <p className='font-20 frame stretch bold'>Back</p>
                    </div>
                </div>
            </div>;
            break;
        case 'availability options': //('featured', 'coming', 'archived', 'available', 'sold', 'outseason')
            overlay = <div className='frame absolute stretch center hover-none' style={{ gap: '4rem', padding: '8rem', width: '100%', height: '100%' }}>
                <div className='frame column center stretch frosted-white' style={{ gap: '8rem', padding: '4rem', width: '100%' }}>
                    <div className='frame center stretch primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { setAvailabilityOnClick('available') }}>
                        <p className='font-20 frame stretch bold'>Available</p>
                    </div>
                    <div className='frame center stretch primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { setAvailabilityOnClick('outseason') }}>
                        <p className='font-20 frame stretch bold'>Out of Season</p>
                    </div>
                    <div className='frame center stretch primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { setAvailabilityOnClick('sold') }}>
                        <p className='font-20 frame stretch bold'>Sold Out</p>
                    </div>
                    <div className='frame center stretch primary inverted hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { setAvailabilityOnClick('archived') }}>
                        <p className='font-20 frame stretch bold'>Archived</p>
                    </div>
                    <div className='frame center primary hover-pointer' style={{ padding: '12rem 24rem', borderRadius: '10rem' }} onClick={() => { backOnClick() }}>
                        <p className='font-20 frame stretch bold'>Cancel</p>
                    </div>
                </div>
            </div>;
            break;
    }

    return (
        <div className='frame relative center stretch column hover-pointer' style={{ gap: '8rem', padding: '29rem 0' }} onClick={() => { onClick() }}>
            <img src={photoMap.get(url)} className={photoMap.get(url) ? '' : 'item-image ' + props.flavor} style={{ height: '232rem', width: '260rem' }}></img>
            <div className='frame column center stretch flex-100' style={{ gap: '4rem', padding: '0 0 0 30rem' }}>
                <p className='font-24 stretch bold' style={{ width: '250rem' }}>{props.name}</p>
                <p className='font-24 faded-grey stretch'>{props.price}</p>
            </div>
            {overlay}
            {statusChange !== status &&
                <img className='frame absolute' src={GreenDot} style={{ right: '12rem', top: '12rem', width: '24rem', height: '24rem' }}></img>}
        </div>
    );
}

export default MenuItemEdit;