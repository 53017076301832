import React, { useEffect, useRef, useState } from 'react';
import GreenDot from '../../assets/GreenDot.png';

export default function Input(props) {
    const inputRef = useRef(null);

    const {
        state, setState,
        selected,
        className, inputStyle, noStretch,
        title, info, placeholder, type,
        maxWords,
        onBlur, onEnter,
        error, setError
    } = props;

    const handleChange = e => {
        const value = e.target.value;
        if (maxWords && value.split(' ').length > maxWords) {
            setState(state);
            return;
        }
        if (type === 'tel') {
            // auto format phone number
            const validatedState = value.replace(/\D/g, '');
            if (validatedState.length > 10) {
                setState(validatedState.slice(0, 10));
            }
            else if (validatedState.length > 6) {
                setState('(' + validatedState.slice(0, 3) + ')' + validatedState.slice(3, 6) + '-' + validatedState.slice(6));
            }
            else if (validatedState.length > 3) {
                setState('(' + validatedState.slice(0, 3) + ')' + validatedState.slice(3));
            }
            else {
                setState(validatedState);
            }
        } else {
            setState(value);
        }
    }

    const handleKeyDown = e => {
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                if (onEnter) onEnter();
                else inputRef.current.blur();
                break;
        }
    }

    const handleInvalid = e => {
        e.preventDefault();
        e.target.setCustomValidity('');
    };

    const handleInput = e => {
        e.preventDefault();
        e.target.setCustomValidity('');
    };

    // console.log('selected: '+selected);
    var currentInputStyle = selected ? inputStyle.selected : inputStyle.default;

    const description = title || info;

    return (
        <div className={'frame column center ' + (noStretch ? '' : 'stretch ') + className} style={{ gap: '12rem' }}>
            {description &&
                <div className='frame row justify-between stretch'>
                    <p className='font-24 semi-bold'>{title}</p>
                    <p className='font-24 primary semi-bold'>{info}</p>
                </div>
            }
            <div className='frame row align-end stretch nowrap' style={currentInputStyle} onClick={() => inputRef.current.focus()}>
                <input className={'frame flex-100 stretch font-20' + (selected ? ' primary' : '')} style={{ width: '100%' }}
                    value={state}
                    placeholder={placeholder}
                    ref={inputRef}
                    type={type}
                    pattern={type === 'tel' ? '\\([0-9]{3}\\) ?[0-9]{3}-[0-9]{4}' : '.*'}
                    onChange={handleChange}
                    onBlur={onBlur}
                    onKeyDown={handleKeyDown}
                    onInvalid={handleInvalid}
                    onInput={handleInput} />
                {maxWords && <p className='font-20 light-grey'>Max: {maxWords} words</p>}
            </div>
            {error &&
                <p className='font-20 primary'>{error}</p>
            }
        </div>
    );
}

export function EditInput(props) {
    const inputRef = useRef(null);

    const {
        ogState,
        state, setState,
        selected,
        className, inputStyle, noStretch,
        title, info, placeholder, type,
        maxWords,
        onBlur, onEnter,
        error, setError,
        number
    } = props;

    const [confirmReset, setConfirmReset] = useState(false);

    useEffect(() => {
        if (number && typeof (ogState) === typeof (0) && typeof (state) !== typeof (0)) {
            const validatedState = state.replace(/\D/g, '');
            if (validatedState) setState(parseFloat(validatedState));
            else setState('');
        }
    }, [state]);

    const inputOnBlur = e => {
        if (onBlur) onBlur(state);
        if (number) {
            if (state === '') {
                setState(0);
            }
        }
    }

    const handleChange = e => {
        const value = e.target.value;
        if (maxWords && value.split(' ').length > maxWords) {
            setState(state);
            return;
        }
        setState(value);
    }

    const handleKeyDown = e => {
        switch (e.key) {
            case 'Enter':
                e.preventDefault();
                if (onEnter) onEnter();
                else inputRef.current.blur();
                break;
        }
    }

    const changed = ogState !== state;

    // console.log('selected: '+selected);
    var currentInputStyle = selected ? inputStyle.selected : inputStyle.default;

    const description = title || info;

    return (
        <div className={'frame relative column center ' + (noStretch ? '' : 'stretch ') + className} style={{ gap: '12rem', backgroundColor: (changed ? 'var(--light-green)' : 'transparent') }}>
            {description &&
                <div className='frame row justify-between stretch'>
                    <p className='font-24 semi-bold'>{title}</p>
                    <p className='font-24 primary semi-bold'>{info}</p>
                </div>
            }
            <div className='frame row align-end stretch nowrap' style={currentInputStyle} onClick={() => inputRef.current.focus()}>
                <input className={'frame flex-100 stretch font-20' + (selected ? ' primary' : '')} style={{ width: '100%' }}
                    value={state}
                    placeholder={placeholder}
                    ref={inputRef}
                    pattern={type === 'tel' ? '\\([0-9]{3}\\) ?[0-9]{3}-[0-9]{4}' : '.*'}
                    onChange={handleChange}
                    onBlur={inputOnBlur}
                    onKeyDown={handleKeyDown} />
                {maxWords && <p className='font-20 light-grey'>Max: {maxWords} words</p>}
            </div>
            {error &&
                <p className='font-20 primary'>{error}</p>
            }
            {changed &&
                <img className='frame absolute hover-pointer' src={GreenDot} style={{ right: '14rem', top: '15%', width: '14rem', height: '14rem' }} onClick={() => {
                    setConfirmReset(true);
                }}></img>}
            {confirmReset &&
                <div className='frame absolute center' style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)', gap: '4rem' }}>
                    <div className='frame center primary inverted hover-pointer' style={{ padding: '6rem 12rem', borderRadius: '6rem' }} onClick={() => setConfirmReset(false)}>
                        <p className='font-18'>Cancel</p>
                    </div>
                    <div className='frame center primary hover-pointer' style={{ padding: '6rem 12rem', borderRadius: '6rem' }} onClick={() => {
                        setState(ogState);
                        setConfirmReset(false);
                    }}>
                        <p className='font-18 nowrap'>Reset Change</p>
                    </div>
                </div>}
        </div>
    );
}