import React from 'react';

import Redirect from './Redirect';

import { photoMap3 as photoMap } from '../../functions/PhotoMap';
import Logo from '../../assets/Logo1.svg';
import MenuItemBackground from '../../assets/MenuItemBackground.jpg';

function MenuItem(props) {
    let parsedURL = props.url.split('/');
    return (
        <Redirect to={'/menu' + props.url}>
            <div className='frame center stretch column' style={{ gap: '8rem', padding: '29rem 0', height: '100%' }}>
                <div className='frame relative center' style={{ height: '232rem', width: '260rem' }}>
                    <img src={MenuItemBackground} className='frame absolute'
                        style={{ height: '232rem', width: '260rem', borderRadius: '24rem' }}></img>
                    <img src={props.thumbnail || (photoMap.get(props.url) ? photoMap.get(props.url) : Logo)} className='frame item-image absolute'
                        style={{ height: '216rem', width: '245rem' }}></img>
                </div>
                <div className='frame column stretch flex-100' style={{ gap: '4rem', padding: '0 0 0 30rem' }}>
                    <p className='font-24 stretch bold' style={{ width: '250rem' }}>{props.name}</p>
                    <p className='font-24 faded-grey stretch'>{props.price}</p>
                </div>
            </div>
        </Redirect>
    );
}

export default MenuItem;